import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const Columns = styled(Col)`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const HeaderDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 80px;
`;

export const Header = styled.h1`
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 55px;
  line-height: 55px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 1399px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 1199px) {
    font-size: 45px;
    line-height: 45px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 33px;
  }
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    width: 75%;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background: #ffc905;
    border-radius: 4px;
  }
`;

export const ListingGridImage = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  width: 100%;
  height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ListingGridDate = styled.div`
  width: 80%;
  margin: -23px auto 0 auto;
  padding: 15px;
  font-family: 'HelveticaNeue-Bold', arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  background: #00a6e8;
  transition: all 0.25s ease-out;
`;

export const ListingGridDetails = styled.div`
  padding: 40px;
`;

export const HeaderH3 = styled.h3`
  text-align: ${(props) => props.textAlign};
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
  @media (max-width: 1399px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const ParaText = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 0 40px 0;
  padding: 0;
  text-align: center;
  :last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Body = styled.div`
  padding: 80px 110px;
  @media (max-width: 1200px) {
    padding: 50px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;

export const BlogAnchor = styled.a`
  height: 95%;
  display: block;
  position: relative;
  flex-basis: 31%;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 15px 35px 15px;
  padding: 0;
  text-align: center;
  background: #fff;
  overflow: hidden;
  box-shadow: 2px 2px 25px rgb(0 0 0 / 10%);
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  :hover {
    transform: scale(1.05);
    text-decoration: none;
    color: #f7991c;
    ${ListingGridDate} {
      background: #f7991c;
    }
    ${HeaderH3} {
      color: #f7991c;
    }
  }

  @media (max-width: 1799px) {
    flex-basis: 30%;
  }
  @media (max-width: 1199px) {
    flex-basis: 45%;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
  }
`;
